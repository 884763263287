<template>
  <div class="phone">
    <video
      src="../assets/video/iphone.mp4"
      autoplay
      loop
      muted
      width="280"
    ></video>
    <img src="../assets/img/iphone.png" alt="iPhone" />
  </div>
</template>

<style lang="scss" scoped>
.phone {
  position: relative;
  min-height: 600px;
  min-width: 340px;
  
  video {
    height: 585px;
    border-radius: 31px;
    position: absolute;
    top: 15px;
    left: 13px;
    z-index: -1;
  }

  img {
    width: 305px;
    height: 605.4px;
    position: absolute;
    z-index: 1;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }

  margin-right: 6em;
}
</style>

<script>
export default {
    name: "PhoneVideo"
}
</script>
